import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})

createApp(App)
  .use(router)
  .use(vuetify)
  // .use(vue3GoogleLogin, {
  //   clientId:
  //     '93334426527-gu15kn501q3je2llea3p4vn7mnh7tvlu.apps.googleusercontent.com'
  // })
  .mount('#app')
