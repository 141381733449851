<template>
  <div class="home">
    <!-- <a href="https://auth.ragnet-ai.com/auth/google/start">Login with Google</a> -->
     <v-btn
      color="primary"
      href="https://auth.ragnet-ai.com/auth/google/start"
    >
      Login with Google
    </v-btn>
  </div>
</template>

<script lang="ts" setup>

</script>
